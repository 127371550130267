import React, { useState, useEffect, useRef } from 'react';
import { Gemeinkosten } from './gemeinkosten/gemeinkosten';

const MkgGemeinkosten = ({currentUser}) => {

  return (
      <div className="w3-container">
          <h2>Gemeinkosten</h2>
          {
            <Gemeinkosten currentUser={currentUser}></Gemeinkosten>
          }
      </div>
  );
};

export { MkgGemeinkosten };