import React, { useState } from 'react';

const TooltipIcon = ({ children }) => {
    const [showTooltip, setShowTooltip] = useState(false);

    const triggerElement = children[0];
    const tooltipContent = children.slice(1);

    return (
        <div 
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
        >
        {triggerElement}
        {showTooltip && (
            <div
                style={{
                    position: 'absolute',
                    top: '20px',
                    left: '30px',
                    backgroundColor: '#fefefe',
                    borderRadius: '3px',
                    zIndex: 1000,
                    pointerEvents: 'auto',
                    transition: 'all 300ms ease',
                    boxShadow: '0 3px 9px 2px rgba(0, 0, 0, 0.15)',
                    maxWidth: '270px',
                    minWidth: '50px',
                    wordBreak: 'normal',
                    overflow: 'hidden',
                    fontSize: '13px',
                    lineHeight: '19.5px',
                    fontWeight: '400',
                    width: 'auto'
                }}
                className='w3-container w3-section'
            >
                {tooltipContent}
            </div>
        )}
        </div>
    );
};

export {TooltipIcon};
