import React, { useState } from "react";

const InputForm = ({data, fields, errors, handleInputChange}) => {

    const [showPassword, setShowPassword] = useState(false);

    const formatDateString = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const togglePasswordVisibility = (e, fieldName) => {
        if (fieldName === 'password') {
            e.stopPropagation();
            setShowPassword(prevState => !prevState);
        }
    };

    return (
        <form key={data.index} className="w3-section w3-bar">
        {fields.map((field, index) => (
            <div key={index} className={`w3-row-padding w3-half w3-bar`} style={{ marginBottom: "16px", padding: '0 0' }}>
                <div className="w3-bar-item w3-margin-right" style={{ width: "40px" }}>
                    <i className={`w3-xlarge w3-text-theme fa ${field.icon}`}></i>
                </div>
                <div className="w3-rest">
                    <input
                        className={`w3-input w3-border ${field.readOnly ? 'w3-light-grey' : ''}`}
                        type={field.key === 'password' ? (showPassword ? 'text' : 'password') : field.type}
                        value={
                            field.type === 'date' ? formatDateString(new Date(data[field.key])) :
                            field.type === 'file' ? undefined : // file inputs should not have a value prop
                            data[field.key]
                        }
                        readOnly={field.readOnly || false}
                        onChange={(e) => handleInputChange(e, field.key, field.type)}
                        style={{
                            cursor: field.readOnly ? 'not-allowed' : '',
                            padding: field.type === 'file' ? '5px' : '8px'
                        }}
                        accept={field.type === 'file' ? (field.key === 'pdf' ? '.pdf' : field.key === 'excel' ? '.xlsx' : '') : undefined}
                    />
                    <div 
                        className="w3-small w3-white w3-round w3-border"
                        style={{
                            position: 'absolute',
                            padding: '0px 3px',
                            margin: '0px 7px',
                            transform: 'translateY(-10px)',
                        }}> 
                        {field.placeholder}
                    </div>
                    {field.key == 'password' && (
                    <div 
                        onClick={(e) => togglePasswordVisibility(e, field.key)}
                        style={{
                            position: 'absolute',
                            right: '30px',
                            transform: 'translateY(-32px)',
                        }}
                    >
                        {showPassword ? (
                            <i className="fa fa-eye-slash"></i>
                        ) : (
                            <i className="fa fa-eye"></i>
                        )}
                    </div>
                    )}
                    {errors[field.key] && (
                        <div 
                            className="w3-small w3-red w3-panel"
                            style={{
                                padding: '0 10px',
                                marginBottom: '0px'
                            }}> 
                            {errors[field.key]}
                        </div>
                    )}
                </div>
            </div>
        ))}
        </form>
        );
    };
    
export { InputForm };