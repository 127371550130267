import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { API } from '../../../services/apiUrl.jsx';
import { Modal } from "../../utility/modal.jsx";
import { InputForm } from "../../utility/form.jsx";
import { TooltipIcon } from '../../utility/tooltipIcon.jsx';
import fileDownload from 'js-file-download';

const CSVSplitter = ({ currentUser }) => {
    
    const dateStringToTimestamp = (dateString) => {
        const [year, month, day] = dateString.split('-').map(Number);
        const dateObject = new Date(year, month - 1, day);
        return dateObject.getTime();
    };

    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [loadingInitial, setLoadingInitial] = useState(false);
    const [changedStates, setChangedStates] = useState(false);

    const [data, setData] = useState({
        rowNumber: 1000,
        header: '',
        file: null,
    });
    const [dataOriginal, setDataOriginal] = useState(data);
    const [errors, setErrors] = useState({});

    const [streamData, setStreamData] = useState([]);

    const saveChanges = () => {
        const validationErrors = validateFields();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            setLoading(true);

            const formData = new FormData();
            formData.append('file', data.file);
            formData.append('rowNumber', data.rowNumber);
            formData.append('header', data.header);

            axios.post(`${API}sgbit/csvSplitter.php`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                responseType: 'blob'
            })
            .then(response => {
                const contentDisposition = response.headers['Content-Disposition'];
                const filename = contentDisposition ? contentDisposition.split('filename=')[1] : 'download.zip';
                fileDownload(response.data, filename.replace(/['"]/g, ''));
                setLoading(false);
                resetChanges();
            })
            .catch(error => {
                const resMessage =
                    (error.response &&
                    error.response.data &&
                    error.response.data.detail) ||
                    error.message ||
                    error.toString();
                const mysqliError = error.response && error.response.data && error.response.data.mysqliError;
                const errorMessage = mysqliError ? `${resMessage}: ${mysqliError}` : resMessage;
                setMessage(errorMessage);
                setLoading(false);
            });
        }
    };

    const resetChanges = () => {
        setData({
            ...dataOriginal, 
            file: null
        });
        setStreamData([]);
        setErrors({});
        setChangedStates(false);
        setLoading(false)
    };

    useEffect(() => {
        const dataIsEqual = JSON.stringify(data) === JSON.stringify(dataOriginal);
        if (dataIsEqual) {
            setChangedStates(false);
        } else {
            setChangedStates(true);
        }
    }, [data]);

    const handleInputChange = (e, key, type) => {
        const value = type === 'file' ? e.target.files[0] : 
                      type === 'date' ? dateStringToTimestamp(e.target.value) : 
                      e.target.value;
        setData(prevState => ({ ...prevState, [key]: value }));
        setErrors({ ...errors, [key]: null });
    };

    const fields = [
        { key: 'rowNumber', type: 'number', placeholder: 'Anzahl der Zeilen pro Teilung', icon: 'fa fa-columns' },
        { key: 'header', type: 'text', placeholder: 'Überschrift nach dessen Werten getrennt werden soll', icon: 'fa fa-align-justify' },
        { key: 'file', type: 'file', placeholder: 'Datei', icon: 'fa fa-file-excel-o' },
    ];

    const validateFields = () => {
        const newErrors = {};
        const csvRegex = /\.csv$/i;

        if (!data.file || !data.file.name || !csvRegex.test(data.file.name)) {
            newErrors.file = 'Lade eine CSV-Datei hoch';
        }
        if ((!data.rowNumber || (isNaN(data.rowNumber) || parseInt(data.rowNumber, 10) <= 0)) && (!data.header || data.header.trim() === '')) {
            newErrors.rowNumber = 'Gib entweder eine Zahl höher als 0 oder einen Header an';
            newErrors.header = 'Gib entweder eine Zahl höher als 0 oder einen Header an';
        } else {
            if (data.rowNumber && (isNaN(data.rowNumber) || parseInt(data.rowNumber, 10) <= 0)) {
                newErrors.rowNumber = 'Gib einen Zahl höher als 0 an';
            }
            if (data.header && data.header.trim() === '') {
                newErrors.header = 'Header muss ausgefüllt sein';
            }
        }

        return newErrors;
    };

    return (            
            <div style={{ position: 'relative' }}>
            {message && (
            <Modal message={message} setMessage={setMessage}/>
            )}
            {loadingInitial ? (    
                <div className="w3-section w3-display-container" style={{height: '200px'}}>
                    <div className="w3-display-middle">
                        <i className='fa fa-spinner fa-spin w3-margin-right w3-large'></i>
                    </div>    
                </div> 
            ) : (
                <React.Fragment>
                <div className="w3-section w3-mobile w3-bar">
                    <div className={`w3-left w3-bar-item`} >
                        <TooltipIcon>
                            <i
                                className='fa fa-info-circle w3-xlarge w3-text-theme'
                                style={{cursor: 'help'}}
                            />
                            <h4>CSV Splitter</h4>
                            <p>
                                Dieses Tool erstellt aus einer CSV mehrere CSV-Dateien und behält die Tabellenüberschrift für alle bei. 
                                Dies kann sinnvoll sein, wenn der Import bei weclapp zu groß wird.
                            </p>
                        </TooltipIcon>
                    </div>
                    {changedStates && (
                    <React.Fragment>
                    <button 
                        className={`w3-button ${loading ? 'w3-button-loading' : 'w3-theme'} w3-right w3-bar-item`} 
                        style={{
                            pointerEvents: loading ? 'none' : 'auto'
                        }}
                        onClick={saveChanges}
                    >
                        {!loading ? (
                            <>
                                <i className='fa fa-save w3-margin-right w3-small'></i>
                            </>
                        ) : (
                            <>
                                <i className='fa fa-spinner fa-spin w3-margin-right w3-small'></i>
                            </>
                        )}
                        Download
                    </button>
                    <button 
                        className={`w3-button ${loading ? 'w3-button-loading' : 'w3-theme'} w3-right w3-bar-item`} 
                        style={{
                            pointerEvents: loading ? 'none' : 'auto',
                            marginRight: '8px'
                        }}
                        onClick={resetChanges}
                    >
                        {!loading ? (
                            <>
                                <i className='fa fa-download w3-margin-right w3-small'></i>
                            </>
                        ) : (
                            <>
                                <i className='fa fa-spinner fa-spin w3-margin-right w3-small'></i>
                            </>
                        )}
                        Zurücksetzen
                    </button>
                    </React.Fragment>
                    )}     
                </div>
                <InputForm
                    data={data} 
                    fields={fields} 
                    errors={errors}
                    handleInputChange={handleInputChange} 
                />
                </React.Fragment>
                )
            }
            </div>       
    );
};

export { CSVSplitter };